import { gql, useQuery, useLazyQuery, QueryHookOptions } from '@apollo/client';

import { Query, QueryBoardArgs } from '../../generated/types';

export const GET_BOARD = gql`
  query board($userUuid: String!, $boardUuid: String!) {
    board(userUuid: $userUuid, boardUuid: $boardUuid) {
      uuid

      title
      description
      linkedin
      availableDate
      location
      locationStreet
      locationPostCode
      locationCity
      locationCountry
      locationUrl
      locationLatitude
      locationLongitude
      educationLevel
      interestLevel
      seniorityLevel
      workRightEU
      workRightUK
      industry
      coreSkills
      suggestedCoreSkills

      resumes {
        uuid
        state
        isNew
        isPublic
        isDeleted
        createdAt
        updatedAt
        createdBy {
          uuid
        }
        updatedBy {
          uuid
        }
      }

      files {
        uuid
        filename
        size
        mimetype
        formattedMimetype
        resource
        createdAt
        updatedAt
        isDeleted
        temporaryUrl
        temporaryPDFUrl
        aiCoreSkills
        aiSoftSkills
        aiJobTitle
        aiIsValidResume
        isProcessed
        temporaryImageUrl
        isConvertedToPDF
      }

      events {
        uuid
        type
        startAt
        endAt
        isDeleted
        createdAt
        updatedAt
        meetingUrl
        location
        locationCity
        locationCountry
        locationLatitude
        locationLongitude
        locationPostCode
        locationStreet
        locationUrl
        description
        createdBy {
          uuid
          email
          nickname
          name
        }
        updatedBy {
          uuid
          email
          nickname
          name
        }
        job {
          uuid
          jobTitle
          status
          company
          boardUuid
          userUuid
        }
      }

      followers {
        uuid
        email
        nickname
        name
        avatar
      }

      isOwner
      owner {
        uuid
        email
        nickname
        name
      }

      createdAt
      createdBy {
        uuid
        email
        nickname
        name
      }
      updatedAt
      updatedBy {
        uuid
        email
        nickname
        name
      }

      permissions
      isPublic
      publicUrl

      isNew
      isDeleted
    }
  }
`;

export const useGetBoard = (options?: QueryHookOptions<Pick<Query, 'board'>, QueryBoardArgs>) =>
  useQuery<Pick<Query, 'board'>, QueryBoardArgs>(GET_BOARD, options);

export const useLazyGetBoard = (options?: QueryHookOptions<Pick<Query, 'board'>, QueryBoardArgs>) =>
  useLazyQuery<Pick<Query, 'board'>, QueryBoardArgs>(GET_BOARD, options);
